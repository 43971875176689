import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"

const CVPage = () => {
	return (
	<Layout>
		<Container>
			<h1 className="text-center mt-5">CV</h1>
			<Row className="mt-5 justify-content-center">
				<Col xs={10} md={8}><p>Coming soon</p></Col>
			</Row>
		</Container>
	</Layout>
	);
}

export default CVPage
